import {useState} from "react";
import {MatchingType, RoomMatchingFormData, RoomType, UserMatchingFormData} from "../types/type";

const replaceToNumber = (value: string) => {
  return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
}

type MatchingFormProps = {
  isFetching: boolean;
  roomData: RoomMatchingFormData | null,
  userData: UserMatchingFormData | null,
  onRoomFetch: (data: RoomMatchingFormData) => void;
  onUserFetch: (data: UserMatchingFormData) => void;
}

const MatchingForm = ({isFetching, roomData, userData, onRoomFetch, onUserFetch}: MatchingFormProps) => {
  const [matchingType, setMatchingType] = useState<MatchingType>("room");
  const [roomMatchingFormData, setRoomMatchingFormData] = useState<RoomMatchingFormData>(roomData ? {
    ...roomData,
    code: ""
  } : {
    type: "farm",
    minLevel: 0,
    isDoubleNeeded: false,
    code: ""
  });
  const [userMatchingFormData, setUserMatchingFormData] = useState<UserMatchingFormData>(userData ? {
    ...userData
  } : {
    type: "farm",
    level: 0,
    hasDouble: false
  });

  const onRoomTypeChange = (value: RoomType) => {
    setRoomMatchingFormData({...roomMatchingFormData, type: value});
  }

  const onRoomCodeChange = (value: string) => {
    const number = replaceToNumber(value);
    setRoomMatchingFormData({...roomMatchingFormData, code: number.substring(0, 4)});
  }

  const onRoomMinLevelChange = (value: string) => {
    const number = replaceToNumber(value);
    setRoomMatchingFormData({
      ...roomMatchingFormData,
      minLevel: number.length === 0 ? 0 : parseInt(number.substring(0, 2))
    });
  }

  const onRoomDoubleChange = (value: boolean) => {
    setRoomMatchingFormData({...roomMatchingFormData, isDoubleNeeded: value});
  }

  const onRoomSubmit = async () => {
    if (isFetching) {
      return;
    }

    if (roomMatchingFormData.code.length !== 4) {
      window.alert("방 번호를 확인해주세요.");
      return;
    }

    onRoomFetch(roomMatchingFormData);
  }

  const onUserRoomTypeChange = (value: RoomType) => {
    setUserMatchingFormData({...userMatchingFormData, type: value});
  }

  const onUserMinLevelChange = (value: string) => {
    const number = replaceToNumber(value);
    setUserMatchingFormData({
      ...userMatchingFormData,
      level: number.length === 0 ? 0 : parseInt(number.substring(0, 2))
    });
  }

  const onUserDoubleChange = (value: boolean) => {
    setUserMatchingFormData({...userMatchingFormData, hasDouble: value});
  }

  const onUserSubmit = async () => {
    if (isFetching) {
      return;
    }

    if (userMatchingFormData.level === 0) {
      window.alert("레벨을 입력해주세요.");
      return;
    }

    onUserFetch(userMatchingFormData);
  }

  return (
    <>
      <h3 className="text-white mb-8 sm:mb-12 font-bold sm:text-4xl text-2xl">운빨존많겜 매치 파인더</h3>
      <div className="flex items-center gap-4 mb-8">
        <div className="flex items-center gap-2">
          <input type="radio" name="appearance" id="room" value="room" className="peer sr-only"
                 checked={matchingType === "room"} onChange={(e) => setMatchingType(e.target.value as MatchingType)} defaultChecked/>
          <label
            htmlFor="room"
            className="text-white font-bold px-6 py-2 rounded-full transition-colors bg-gray-600 peer-checked:bg-green-600 cursor-pointer"
          >
            방 만들기
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input type="radio" name="appearance" id="user" value="user" className="peer sr-only"
                 checked={matchingType === "user"} onChange={(e) => setMatchingType(e.target.value as MatchingType)}/>
          <label
            htmlFor="user"
            className="text-white font-bold px-6 py-2 rounded-full transition-colors bg-gray-600 peer-checked:bg-blue-600 cursor-pointer"
          >
            방 구하기
          </label>
        </div>
      </div>
      <div className="bg-white rounded-lg p-8 w-full max-w-md shadow-lg">
        {
          matchingType === "room" ? (
            <div>
              <div>
                <label className="block mb-2 text-s font-bold text-gray-900">방 종류</label>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <input type="radio" name="farm" id="farm" value="farm" className="peer sr-only"
                           checked={roomMatchingFormData.type === "farm"}
                           onChange={(e) => onRoomTypeChange(e.target.value as RoomType)}/>
                    <label
                      htmlFor="farm"
                      className="text-xs peer-checked:font-bold text-black peer-checked:text-white px-6 py-2 rounded-full border border-gray-600 peer-checked:bg-blue-500 peer-checked:border-blue-500 cursor-pointer"
                    >
                      재획
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input type="radio" name="bus-driver" id="bus-driver" value="bus-driver" className="peer sr-only"
                           checked={roomMatchingFormData.type === "bus-driver"}
                           onChange={(e) => onRoomTypeChange(e.target.value as RoomType)}/>
                    <label
                      htmlFor="bus-driver"
                      className="text-xs peer-checked:font-bold text-black peer-checked:text-white px-6 py-2 rounded-full border border-gray-600 peer-checked:bg-blue-500 peer-checked:border-blue-500 cursor-pointer"
                    >
                      기사 구함
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input type="radio" name="bus-passenger" id="bus-passenger" value="bus-passenger"
                           className="peer sr-only" checked={roomMatchingFormData.type === "bus-passenger"}
                           onChange={(e) => onRoomTypeChange(e.target.value as RoomType)}/>
                    <label
                      htmlFor="bus-passenger"
                      className="text-xs peer-checked:font-bold text-black peer-checked:text-white px-6 py-2 rounded-full border border-gray-600 peer-checked:bg-blue-500 peer-checked:border-blue-500 cursor-pointer"
                    >
                      손님 구함
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="code" className="mt-8 block mb-2 text-s font-bold text-gray-900">
                  방 번호
                </label>
                <input
                  type="text"
                  id="code"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="방 번호 (4자리)"
                  value={roomMatchingFormData.code}
                  onChange={e => onRoomCodeChange(e.target.value)}
                />
              </div>
              <div className="flex gap-8 mt-4">
                <div className="flex-1">
                  <label htmlFor="level" className="block mb-2 text-s font-bold text-gray-900">
                    최소 레벨
                  </label>
                  <input
                    type="text"
                    id="level"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                    placeholder="최소 레벨"
                    value={roomMatchingFormData.minLevel}
                    onChange={e => onRoomMinLevelChange(e.target.value)}
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="double" className="block mb-2 text-s font-bold text-gray-900">
                    2배속 필수
                  </label>
                  <input
                    id="double"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    checked={roomMatchingFormData.isDoubleNeeded}
                    onChange={e => onRoomDoubleChange(e.target.checked)}
                  />
                </div>
              </div>
              <button
                className="mt-8 font-bold w-full px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={onRoomSubmit}
                disabled={isFetching}
              >
                만들기
              </button>
            </div>
          ) : (
            <div>
              <div>
                <label className="block mb-2 text-s font-bold text-gray-900">방 종류</label>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <input type="radio" name="farm" id="farm" value="farm" className="peer sr-only"
                           checked={userMatchingFormData.type === "farm"}
                           onChange={(e) => onUserRoomTypeChange(e.target.value as RoomType)}/>
                    <label
                      htmlFor="farm"
                      className="text-xs peer-checked:font-bold text-black peer-checked:text-white px-6 py-2 rounded-full border border-gray-600 peer-checked:bg-blue-500 peer-checked:border-blue-500 cursor-pointer"
                    >
                      재획
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input type="radio" name="bus-driver" id="bus-driver" value="bus-driver" className="peer sr-only"
                           checked={userMatchingFormData.type === "bus-driver"}
                           onChange={(e) => onUserRoomTypeChange(e.target.value as RoomType)}/>
                    <label
                      htmlFor="bus-driver"
                      className="text-xs peer-checked:font-bold text-black peer-checked:text-white px-6 py-2 rounded-full border border-gray-600 peer-checked:bg-blue-500 peer-checked:border-blue-500 cursor-pointer"
                    >
                      기사 찾기
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input type="radio" name="bus-passenger" id="bus-passenger" value="bus-passenger"
                           className="peer sr-only" checked={userMatchingFormData.type === "bus-passenger"}
                           onChange={(e) => onUserRoomTypeChange(e.target.value as RoomType)}/>
                    <label
                      htmlFor="bus-passenger"
                      className="text-xs peer-checked:font-bold text-black peer-checked:text-white px-6 py-2 rounded-full border border-gray-600 peer-checked:bg-blue-500 peer-checked:border-blue-500 cursor-pointer"
                    >
                      손님 찾기
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex gap-8 mt-4">
                <div className="flex-1">
                  <label htmlFor="level" className="block mb-2 text-s font-bold text-gray-900">
                    내 레벨
                  </label>
                  <input
                    type="text"
                    id="level"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                    placeholder="레벨"
                    value={userMatchingFormData.level}
                    onChange={e => onUserMinLevelChange(e.target.value)}
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="double" className="block mb-2 text-s font-bold text-gray-900">
                    2배속 유무
                  </label>
                  <input
                    id="double"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                    checked={userMatchingFormData.hasDouble}
                    onChange={e => onUserDoubleChange(e.target.checked)}
                  />
                </div>
              </div>
              <button
                className="mt-8 font-bold w-full px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={onUserSubmit}
                disabled={isFetching}
              >
                구하기
              </button>
            </div>
          )
        }
      </div>
    </>
  )
};

export default MatchingForm;