import {RoomType} from "../types/type";

const host = "https://bedshanty.xyz";

const roomTypeToString = (roomType: RoomType) => roomType === "farm" ? "FARM" : roomType === "bus-driver" ? "BUS_DRIVER" : "BUS_PASSENGER";

export type CreateRoomResponse = {
  roomId: string | undefined;
}

export type CreateSubscribeResponse = {
  roomCode: string | undefined;
  subscribeId: string | undefined;
}

export type GetRoomResponse = {
  isMatched: boolean;
}

export type GetSubscribeResponse = {
  roomCode: string | undefined;
}

export const createRoom = async (roomType: RoomType, code: string, minLevel: number, doubleNeeded: boolean) => {
  try {
    const response = await fetch(`${host}/matching/room`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: code,
        roomCondition: {
          roomType: roomTypeToString(roomType),
          double: doubleNeeded,
          level: minLevel
        }
      })
    });

    if (!response.ok) {
      return null;
    }

    return await response.json() as CreateRoomResponse;
  } catch {
    return null;
  }
};

export const createSubscribe = async (roomType: RoomType, level: number, hasDouble: boolean) => {
  try {
    const response = await fetch(`${host}/matching/subscribe`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        roomCondition: {
          roomType: roomTypeToString(roomType),
          double: hasDouble,
          level: level
        }
      })
    });

    if (!response.ok) {
      return null;
    }

    return await response.json() as CreateSubscribeResponse;
  } catch {
    return null;
  }
};

export const deleteRoom = async (roomId: string) => {
  try {
    const response = await fetch(`${host}/matching/room?roomId=${roomId}`, {
      method: "DELETE"
    });

    return response.ok;
  } catch {
    return false;
  }
}

export const deleteUser = async (userId: string) => {
  try {
    const response = await fetch(`${host}/matching/user?userId=${userId}`, {
      method: "DELETE"
    });

    return response.ok;
  } catch {
    return false;
  }
}

export const getRoom = async (roomId: string) => {
  try {
    const response = await fetch(`${host}/matching/room?roomId=${roomId}`);

    if (!response.ok) {
      return null;
    }

    return await response.json() as GetRoomResponse;
  } catch {
    return null;
  }
}

export const getSubscribe = async (subscribeId: string) => {
  try {
    const response = await fetch(`${host}/matching/subscribe?subscribeId=${subscribeId}`);

    if (!response.ok) {
      return null;
    }

    return await response.json() as GetSubscribeResponse;
  } catch {
    return null;
  }
}