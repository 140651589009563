import MatchingForm from "./MatchingForm";
import {useState} from "react";
import {
  createRoom,
  CreateRoomResponse,
  createSubscribe,
  CreateSubscribeResponse,
  deleteRoom,
  deleteUser
} from "../network/network";
import MatchingRoom from "./MatchingRoom";
import {RoomMatchingFormData, UserMatchingFormData} from "../types/type";
import MatchingUser from "./MatchingUser";

const MainPage = () => {
  const [isFetching, setFetching] = useState(false);
  const [roomData, setRoomData] = useState<RoomMatchingFormData | null>(null);
  const [userData, setUserData] = useState<UserMatchingFormData | null>(null);
  const [roomFetchResult, setRoomFetchResult] = useState<CreateRoomResponse | null>();
  const [userFetchResult, setUserFetchResult] = useState<CreateSubscribeResponse | null>();

  const onRoomFetch = async (data: RoomMatchingFormData) => {
    setFetching(true);
    setRoomData(data);
    const response = await createRoom(data.type, data.code, data.minLevel, data.isDoubleNeeded)
    setFetching(false);

    if (response == null) {
      return;
    }

    setRoomFetchResult(response);
  }

  const onUserFetch = async (data: UserMatchingFormData) => {
    setFetching(true);
    setUserData(data);
    const response = await createSubscribe(data.type, data.level, data.hasDouble)
    setFetching(false);

    if (response == null) {
      return;
    }

    setUserFetchResult(response);
  }

  const cancelRoom = async () => {
    if (!roomFetchResult?.roomId) {
      return;
    }
    await deleteRoom(roomFetchResult.roomId);
    clearFetchResult();
  }

  const cancelSubscribe = async () => {
    if (!userFetchResult?.subscribeId) {
      return;
    }
    await deleteUser(userFetchResult.subscribeId);
    clearFetchResult();
  }

  const refreshRoom = async () => {
    setFetching(true);
    setTimeout(async () => {
      if (roomData) {
        await onRoomFetch(roomData);
      }
      setFetching(false);
    }, 1000);
  }

  const refreshUser = async () => {
    setUserFetchResult({ roomCode: undefined, subscribeId: undefined });
    setFetching(true);
    setTimeout(async () => {
      if (userData) {
        await onUserFetch(userData);
      }
      setFetching(false);
    }, 1000);
  }

  const clearFetchResult = () => {
    setRoomFetchResult(null);
    setUserFetchResult(null);
  }

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-gray-950">
      {
        roomFetchResult != null ? (
          <div>
            <MatchingRoom isFetching={isFetching} roomId={roomFetchResult.roomId} onCancelClick={cancelRoom} onBackClick={clearFetchResult}
                          onRefreshClick={refreshRoom}/>
          </div>
        ) : userFetchResult != null ? (
          <div>
            <MatchingUser isFetching={isFetching} subscribeId={userFetchResult.subscribeId}
                          foundRoomCode={userFetchResult.roomCode} onCancelClick={cancelSubscribe} onBackClick={clearFetchResult}
                          onRefreshClick={refreshUser}/>
          </div>
        ) : (
          <MatchingForm isFetching={isFetching} roomData={roomData} userData={userData} onRoomFetch={onRoomFetch}
                        onUserFetch={onUserFetch}/>
        )
      }
    </div>
  )
    ;
}

export default MainPage;

