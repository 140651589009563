import CheckIcon from "./CheckIcon";
import {useEffect, useState} from "react";
import {getSubscribe} from "../network/network";

type MatchingUserProps = {
  isFetching: boolean;
  subscribeId: string | undefined;
  foundRoomCode: string | undefined;
  onCancelClick: () => void;
  onBackClick: () => void;
  onRefreshClick: () => void;
}

let latestIntervalId: NodeJS.Timer | undefined = undefined;
let latestTimeout: NodeJS.Timer | undefined = undefined;

const MatchingUser = ({
                        isFetching,
                        subscribeId,
                        foundRoomCode,
                        onCancelClick,
                        onBackClick,
                        onRefreshClick
                      }: MatchingUserProps) => {
  const [roomCode, setRoomCode] = useState<string | undefined>(foundRoomCode);
  const [lock, setLock] = useState<boolean>(foundRoomCode !== undefined);

  useEffect(() => {
    if (foundRoomCode) {
      setRoomCode(foundRoomCode);
      startLock();
      return;
    }

    if (!subscribeId) {
      return;
    }

    setRoomCode(undefined);

    if (latestIntervalId !== null) {
      clearInterval(latestIntervalId);
    }

    const intervalId = setInterval(async () => {
      const response = await getSubscribe(subscribeId);

      if (response?.roomCode) {
        setRoomCode(response.roomCode);
        startLock();
        clearInterval(intervalId)
      }
    }, 1000);
    latestIntervalId = intervalId;
  }, [foundRoomCode, subscribeId]);

  const startLock = () => {
    if (latestTimeout) {
      clearTimeout(latestTimeout);
    }

    setLock(true);

    latestTimeout = setTimeout(() => {
      setLock(false);
    }, 15000);
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      {
        roomCode ? (
          <>
            <div className="bg-green-500 p-4 rounded-full animate-pulse">
              <CheckIcon className="h-12 w-12 text-white"/>
            </div>
            <h1 className="sm:text-2xl font-bold text-white mt-8 text-center">방을 찾았습니다! 코드를 입력하세요.</h1>
            <h1 className="sm:text-8xl text-6xl font-bold text-white mt-8 text-center">{roomCode}</h1>
            <div className="flex gap-8 mt-16">
              <button
                className="font-bold px-8 py-4 text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={onBackClick}
                disabled={isFetching}
              >
                돌아가기
              </button>
              {
                lock ? (
                  <button
                    className="font-bold px-8 py-4 text-white bg-gray-400 rounded-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    15초 뒤 다시 찾기 가능
                  </button>
                ) : (
                  <button
                    className="font-bold px-8 py-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => {
                      setRoomCode(undefined);
                      onRefreshClick();
                    }}
                    disabled={isFetching}
                  >
                    다시 찾기
                  </button>
                )
              }
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-12 justify-center items-center">
            <h1 className="sm:text-4xl font-bold text-white mt-8 text-center">방을 찾는 중입니다..</h1>
            <button
              className="font-bold px-8 py-4 text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => {
                clearInterval(latestIntervalId);
                clearTimeout(latestTimeout);
                onCancelClick();
              }}
            >
              취소하기
            </button>
          </div>
        )
      }
    </div>
  )
};

export default MatchingUser;